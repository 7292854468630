import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import getLocaleString from "utils/getLocaleString";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default ({ locale }) => {
  const cards = [
    {
      imageSrc: "/imagesHussein/marrakech.jpeg",
      title: "Marrakech",
      description: getLocaleString("Feature4Description", locale),
    },
    {
      imageSrc: "/imagesHussein/casablanca.jpg",
      title: "Casablanca",
      description: getLocaleString("Feature1Description", locale),
    },
    {
      imageSrc: "/imagesHussein/merzouga.jpg",
      title: "Merzouga",
      description: getLocaleString("Feature2Description", locale),
    },

    {
      imageSrc: "/imagesHussein/essaouira.jpg",
      title: "Essaouira",
      description: getLocaleString("Feature3Description", locale),
    },
    {
      imageSrc: "/imagesHussein/woman-bomalndads.jpeg",
      title: "Atlas, Woman Bomalndads",
      description: getLocaleString("Feature5Description", locale),
    },
    {
      imageSrc: "/imagesHussein/fes.jpeg",
      title: "Fès",
      description: getLocaleString("Feature6Description", locale),
    },
    {
      imageSrc: "/imagesHussein/chefchaouen.jpeg",
      title: "Chefchaouen",
      description: getLocaleString("Feature7Description", locale),
    },
    {
      imageSrc: "/imagesHussein/rabat.jpeg",
      title: "Rabat",
      description: getLocaleString("Feature8Description", locale),
    },
  ];

  return (
    <Container id="popularPlaces">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            {getLocaleString("PopularPlaces", locale)}
          </HeadingTitle>
          <HeadingDescription>
            {getLocaleString("PopularPlacesDescription", locale)}
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
