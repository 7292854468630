import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";

Modal.setAppElement("#root");

const locale = localStorage.getItem("locale");

if (locale === undefined) {
  const userLang = navigator.language || navigator.userLanguage;
  localStorage.setItem("locale", userLang.substring(0, 2));
}

ReactDOM.render(<App />, document.getElementById("root"));
