import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
/*
import SliderCard from "components/cards/ThreeColSlider.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
*/
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import GetStarted from "components/cta/GetStarted";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";

const getLocaleFromCountry = (country) => {
  switch (country) {
    case "FR":
      return "fr";
    case "ES":
      return "es";
    default:
      return "en";
  }
};

const updateLocaleStorage = (country) => {
  switch (country) {
    case "FR":
      localStorage.setItem("locale", "fr");
      break;
    case "ES":
      localStorage.setItem("locale", "es");
      break;
    default:
      localStorage.setItem("locale", "en");
      break;
  }
};

const getCountryCode = () => {
  const locale = localStorage.getItem("locale");

  switch (locale) {
    case "fr":
      return "FR";
    case "es":
      return "ES";
    default:
      return "US";
  }
};

const Tifinagh = () => {
  const [selected, setSelected] = useState(getCountryCode());
  const locale = getLocaleFromCountry(selected);

  useEffect(() => {
    updateLocaleStorage(selected);
  }, [selected]);

  return (
    <AnimationRevealPage>
      <Hero selected={selected} setSelected={setSelected} locale={locale} />
      <FeatureStats locale={locale} />
      <Features locale={locale} />
      <FAQ locale={locale} />
      <GetStarted locale={locale} />
      <Footer locale={locale} />
    </AnimationRevealPage>
  );
};

export default Tifinagh;
