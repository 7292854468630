import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactFlagsSelect from "react-flags-select";
import getLocaleString from "utils/getLocaleString.js";

import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const carouselImages = [
  "/images/2.jpg",
  "/images/4.jpg",
  "/images/5.jpg",
  "/images/7.jpg",
  "/images/8.jpg",
  "/images/9.jpg",
  "/images/10.jpg",
  "/images/11.jpg",
  "/images/12.jpg",
  "/images/13.jpg",
];

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const BackgroundAsImageWithCenteredContent = ({
  selected,
  setSelected,
  locale,
}) => {
  const [imageHeight, setImageHeight] = useState(window.innerHeight);

  const handleResize = () => {
    if (imageHeight !== window.innerHeight) {
      setImageHeight(window.innerHeight);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToAbout = () => {
    window.scrollBy(0, window.innerHeight);
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">{getLocaleString("Home", locale)}</NavLink>
      <NavLink onClick={goToAbout}>
        {getLocaleString("AboutUs", locale)}
      </NavLink>
      <NavLink href="#popularPlaces">
        {getLocaleString("PopularPlaces", locale)}
      </NavLink>
      <NavLink href="#contact">{getLocaleString("Contact", locale)}</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <ReactFlagsSelect
        className="flagSelect"
        selected={selected}
        countries={["US", "FR", "ES"]}
        customLabels={{ US: "English", FR: "Français", ES: "Español" }}
        onSelect={(code) => {
          setSelected(code);
        }}
      />
    </NavLinks>,
  ];

  return (
    <Container>
      <div
        style={{
          position: "absolute",
          backgroundColor: "gray",
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          dynamicHeight={false}
        >
          {carouselImages.map((carouselImage) => (
            <div>
              <img
                style={{
                  height: imageHeight,
                  objectFit: "cover",
                  filter: "brightness(50%)",
                }}
                src={carouselImage}
                alt="background carousel"
              />
            </div>
          ))}
        </Carousel>
      </div>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            {getLocaleString("HeroTitle1", locale)}
            <br />
            {getLocaleString("HeroTitle2", locale)}
          </Heading>
          <PrimaryAction onClick={goToAbout}>
            {getLocaleString("IAmInteressted", locale)}
          </PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};

export default BackgroundAsImageWithCenteredContent;
