import en from "../locale/en.json";
import fr from "../locale/fr.json";
import es from "../locale/es.json";

export default (key, locale) => {
  switch (locale) {
    case "es":
      return es[key];
    case "fr":
      return fr[key];
    default:
      return en[key];
  }
};
